<template>
  <div>
    <v-row no-gutters class="generic-category-header">
      <v-col v-for="proposal in proposals" :key="proposal.id" cols="12" md="6">
        <div v-if="!proposal.descr" class="pb-2 pa-0 pl-0 pr-md-2 pb-md-0">
          <v-img :src="proposal.img" alt="Immagine categoria"></v-img>
        </div>
        <div v-else class="pa-0 pt-2 pr-0 pt-md-0 pl-md-2 h-100">
          <div class="white pa-3 rounded h-100">
            <h3 class="font-weight-bold">{{ proposal.descr }}</h3>
            <div v-html="proposal.content"></div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
.generic-category-header {
  border-radius: $border-radius-root;
}
</style>
<script>
export default {
  name: "CategoryHeader",
  props: {
    proposals: { type: Array, required: true }
  }
};
</script>
